@media (min-width: #{map-get($breakpoints, tablet)}) {
	.visible-phone {
		display: none !important;
	}
}

@media (max-width: #{map-get($breakpoints, tablet) - 1}) {	
	.hidden-phone {
		display: none !important;
	}

	.visible-phone {
		display: inherit !important;
	}
}
