*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	&:last-child:not(:first-child):not([class$='subtitle']) {
		margin-bottom: 0;
	}
}

html {
	font-family: sans-serif;
	-ms-overflow-style: scrollbar;
	overflow-y: scroll;
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
iframe,
section {
	display: block;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin: 0;
	text-align: left;
}

[tabindex='-1']:focus {
	outline: none !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	margin-top: 0;
}

p {
	margin-bottom: 1rem;
	margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
	border-bottom: 0;
	cursor: help;
	text-decoration: underline;
	text-decoration: underline dotted;
}

address {
	font-style: normal;
	line-height: inherit;
	margin-bottom: 1rem;
}

ol,
ul,
dl {
	margin-bottom: 1rem;
	margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

iframe {
	border: none;
	margin: 0;
	padding: 0;
}

small {
	font-size: 87.5%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

// Looking for the a tag? Look in links.scss

pre,
code,
kbd,
samp {
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

pre {
	margin-top: 0;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

figure {
	margin: 0 0 1rem;
}

img {
	border-style: none;
	vertical-align: middle;
}

svg:not(:root) {
	overflow: hidden;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

table {
	border-collapse: collapse;
}

caption {
	caption-side: bottom;
	color: #868e96;
	padding-bottom: 0.75rem;
	padding-top: 0.75rem;
	text-align: left;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
}

button {
	border-radius: 0;
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

input[type='radio'],
input[type='checkbox'] {
	box-sizing: border-box;
	padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	border: 0;
	margin: 0;
	min-width: 0;
	padding: 0;
}

legend {
	color: inherit;
	display: block;
	font-size: 1.5rem;
	line-height: inherit;
	margin-bottom: 0.5rem;
	max-width: 100%;
	padding: 0;
	white-space: normal;
	width: 100%;
}

progress {
	vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: none;
	outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

.secure-fields__input--number {
	background-color: #ffffff;
    border: 1px solid #D9D9D9;
    border-radius:8px;
    display: block;
    height: 40px;
	padding-left: spacing(8);
    width: 429px;

  }

  .secure-fields__input {
	background-color: #ffffff;
	border: #D9D9D9;
    border-radius:4px;
    display: block;
    height: 40px;
    width: 100%;
	z-index: 0;
  }

  .secure-fields__input--security-code{
	border: 1px solid #D9D9D9;
    border-radius:4px;
	display: block;
    height: 40px;
    width: 80px;
  }

  .secure-fields__input--expiry-date
  {
	border: 1px solid #D9D9D9;
    border-radius:4px;
	display: block;
    height: 40px;
    width: 141px;
  }

  .wordwrapnpadding{
	font-size: 12px;
	padding-bottom: -2px;
	vertical-align: middle;	
	word-wrap: break-word;
  }

  .radio-text
  {
	color:#007837;
	font-size: 16px;
	height: 20px;
	width:20px;
  }