/// Removes default list style to lists
/// @name list-reset
/// @group mixins
/// @param $reset-children - Removes bottom margin normally added to list items
@mixin list-reset($reset-children: false) {
	list-style: none;
	margin: 0;
	padding: 0;

	@if $reset-children {
		> li {
			margin-bottom: 0;
		}
	}
}
