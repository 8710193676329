@use 'sass:math';

@import './lib/4_atoms/grid';

/// Applies the legacy span / grid layout
/// @name legacy-fluid

/// 
.row-fluid {
  width: 100%;

  .row-content {
    display: grid;
    grid-column-gap: 1rem;
    -ms-grid-columns: (8.33333%) [12];
    grid-row-gap: 1rem;
    grid-template-columns: repeat(12, minmax(0, 8.33333%));
    justify-content: center;
  }

  @include not-ie() {
    @include grid-init();

    &>*:not([class*='button']) {
      @include grid-column(12);
    }
  }

  @include target-ie() {
    @include display-flex($flex-wrap: wrap);
  }

  @include media-query($size: tablet, $max: true) {
    @for $i from 1 to 13 {
      .mspan#{$i} {
        @include grid-column($span: $i);
      }
    }
  }

  @include media-query($size: tablet) {
    @for $i from 1 to 13 {
      .span#{$i} {
        @include not-ie() {
          @include grid-column($span: $i);

          width: 100%;
        }

        @include target-ie() {
          -ms-flex: 1 1 #{math.div($i , 12) * 100%};
          flex: 1 1 #{math.div($i , 12) * 100%} !important;
          width: auto;
        }
      }

      .offset#{$i}:before {
        content: '';
        grid-row: #{math.div($i , -1)};

        @include grid-column(#{math.div(1 , $i)});
      }
    }
  }

}