// BASE STYLES IMPORT
// This should only import the first 3 non specific parts
// of the pyramid. Other items should be imported
// directly via the component


// 1. Settings

@import './lib/1_settings/_config';

// Fonts get imported through Pluto and rerouted
@import './lib/1_settings/accessibility';
@import './lib/1_settings/animations';
@import './lib/1_settings/images';
@import './lib/1_settings/layouts';
@import './lib/1_settings/print';
@import './lib/1_settings/svg';
@import './lib/1_settings/z-index';

// 2. Tools
// Functions
@import './lib/2_tools/functions/apply-scale';
@import './lib/2_tools/functions/build-animation';
@import './lib/2_tools/functions/build-transition';
@import './lib/2_tools/functions/capitalise';
@import './lib/2_tools/functions/map-deep-get';
@import './lib/2_tools/functions/map-deep-keys';
@import './lib/2_tools/functions/pow';
@import './lib/2_tools/functions/str-replace';
@import './lib/2_tools/functions/svg';


// Mixins
@import './lib/2_tools/mixins/apply-spacing';
@import './lib/2_tools/mixins/borders';
@import './lib/2_tools/mixins/center';
@import './lib/2_tools/mixins/color';
@import './lib/2_tools/mixins/display-flex';
@import './lib/2_tools/mixins/ellipsis';
@import './lib/2_tools/mixins/focus-ring';
@import './lib/2_tools/mixins/icon-inline';
@import './lib/2_tools/mixins/list-reset';
@import './lib/2_tools/mixins/media-query';
@import './lib/2_tools/mixins/position';
@import './lib/2_tools/mixins/pseudo';
@import './lib/2_tools/mixins/target-ie';
@import './lib/2_tools/mixins/target-touch';
@import './lib/2_tools/mixins/triangle';

// 3. Generic
// Resets and base themes here only!
@import '@angular/cdk/overlay-prebuilt.css';
@import './lib/3_generic/reboot';
// Angular will strip this, so we also add it to the head of the index.template.html
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');
@import './lib/3_generic/atoms';
@import './lib/3_generic/overlay';
@import './lib/3_generic/gigya';
@import './lib/3_generic/device';
