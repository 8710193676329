@import '../1_settings/spacing';

$svg-size: $spacing-8;

// These need to be upgraded to be the 32px versions instead of the 24px versions used in legacy

// currently @ 32px:
// cross-ui
// hide-password
// show-password
// tick

/**
 * @sass-export-section="icons"
 */
$svg-alarm: 'M22.8305 2.16763C21.3157.652872 18.8746.606032 17.3029 2.06156c2.4878 1.14866 4.4851 3.14592 5.6338 5.6337 1.4555-1.5718 1.4086-4.01288-.1062-5.52763zm-20.66282.00124C3.68196.654617 6.12205.607237 7.69397 2.06156h.0001c-2.48779 1.14863-4.48508 3.14586-5.6338 5.6336C.605972 6.12321.653397 3.68313 2.16768 2.16887zM22.9985 12.4998c.0019 3.4781-1.7208 6.731-4.5989 8.684l1.5462 3.0924c.1235.247.0234.5473-.2236.6709-.247.1235-.5474.0234-.6709-.2236l-1.5076-3.0154c-3.1429 1.7222-6.9472 1.7222-10.09003 0l-1.5079 3.0153c-.12352.247-.42389.3471-.6709.2236-.24701-.1236-.34712-.4239-.2236-.6709l1.5461-3.0923C2.4624 18.3742.881668 13.0389 2.81878 8.43028 4.75589 3.82167 9.67362 1.21785 14.5741 2.2061c4.9006.98825 8.4248 5.29449 8.4244 10.2937zm-10-.2072l3.8532 3.8532v-.0002c.1953.1952.1953.5118 0 .7071-.1952.1953-.5118.1953-.7071.0001l-4-4-.0038-.0056c-.0908-.0925-.1419-.2167-.1423-.3462V6.49976c0-.27614.2238-.5.5-.5.2761 0 .5.22386.5.5v5.79284z';
$svg-alert: 'M11.3 15.8c-.2.2-.3.4-.3.7 0 .6.4 1 1 1s1-.4 1-1-.4-1-1-1c-.3 0-.5.1-.7.3zM12 6c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1s1-.4 1-1V7c0-.6-.4-1-1-1zm0-6C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10z';
$svg-chevron-down: 'M2.31 9.31a1.06 1.06 0 0 1 1.49 0l8.2 8.16 8.2-8.16a1.06 1.06 0 0 1 1.49 0 1 1 0 0 1 0 1.48l-9 8.9a1 1 0 0 1-1.48 0l-8.95-8.9a1 1 0 0 1-.26-.74 1 1 0 0 1 .31-.74z';
$svg-chevron-left: 'M15.69 2.31a1.06 1.06 0 0 1 0 1.49L7.53 12l8.16 8.2a1.06 1.06 0 0 1 0 1.49 1 1 0 0 1-1.48 0l-8.9-9a1 1 0 0 1 0-1.48l8.9-8.95A1 1 0 0 1 15 2a1 1 0 0 1 .69.31z';
$svg-chevron-right: 'M6.31 21.69a1.06 1.06 0 0 1 0-1.49l8.16-8.2-8.16-8.2a1.06 1.06 0 0 1 0-1.49 1 1 0 0 1 1.48 0l8.9 9a1 1 0 0 1 0 1.48l-8.9 9a1 1 0 0 1-.74.31 1 1 0 0 1-.74-.41z';
$svg-chevron-up: 'M21.69 17.69a1.06 1.06 0 0 1-1.49 0L12 9.53l-8.2 8.16a1.06 1.06 0 0 1-1.49 0 1 1 0 0 1 0-1.48l9-8.9a1 1 0 0 1 1.48 0l9 8.9A1 1 0 0 1 22 17a1 1 0 0 1-.31.69z';
$svg-cross-ui: 'M27 28c-.3 0-.5-.1-.7-.3L16 17.5 5.8 27.7c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5L14.5 16 4.3 5.8c-.4-.4-.4-1.1 0-1.5s1.1-.4 1.5 0L16 14.5 26.2 4.3c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5L17.5 16l10.2 10.2c.4.4.4 1.1 0 1.5-.2.2-.5.3-.7.3z';
$svg-heart: 'M17.76 1C15.17 1 13.09 2.94 12 4.2 10.93 2.94 8.86 1 6.24 1 2.51 1 0 3.69 0 7.7c0 7.11 11 14.6 11.45 14.92L12 23l.54-.31C13 22.37 24 14.89 24 7.7 24 3.69 21.49 1 17.76 1zm-5.71 19.6c-4-2.85-10-8.39-10-12.9C2 4.8 3.62 3 6.24 3c1.84 0 3.45 1.57 4.24 2.5L12 7.28l1.52-1.78c.8-.93 2.42-2.5 4.24-2.5C20.38 3 22 4.8 22 7.7c0 4.55-6 10.04-9.95 12.9z';
$svg-heart-s: 'M17.76 1C15.17 1 13.09 3 12 4.22 10.93 2.94 8.86 1 6.24 1 2.51 1 0 3.7 0 7.72c0 7.14 11 14.66 11.45 15l.55.38.54-.31C13 22.45 24 14.94 24 7.72 24 3.7 21.49 1 17.76 1z';
$svg-heart-lg: 'M16.006 5.567C17.45 3.875 20.229 1.28 23.68 1.28c4.977 0 8.32 3.6 8.32 8.96 0 9.558-14.437 19.489-15.258 20.054l-.016.01-.73.416-.727-.506a37.482 37.482 0 0 1-.031-.022C14.268 29.521 0 19.636 0 10.24c0-5.36 3.343-8.96 8.32-8.96 3.488 0 6.252 2.592 7.686 4.287zM1.28 10.24C1.28 19.2 16 29.163 16 29.163s14.72-9.856 14.72-18.923c0-4.48-2.56-7.68-7.04-7.68-4.373 0-7.68 5.12-7.68 5.12s-3.253-5.12-7.68-5.12c-4.48 0-7.04 3.2-7.04 7.68z';
$svg-hide-password: (
	'M7.84 20.62l1.43-1.43a16.9 16.9 0 0 1-3-3.19c1.09-1.65 5.12-6 9.73-6a8.23 8.23 0 0 1 2.16.31l1.58-1.59A10.67 10.67 0 0 0 16 8C9.37 8 4 15.06 4 16c0 .49 1.48 2.66 3.84 4.62zm16.33-9.25l-1.42 1.42A16.51 16.51 0 0 1 25.8 16c-1.1 1.62-5.11 6-9.8 6a8.15 8.15 0 0 1-2.15-.31l-1.59 1.58c1.191.47 2.46.718 3.74.73 6.63 0 12-7 12-8 0-.52-1.48-2.69-3.83-4.63z',
	'M16 20a4 4 0 0 0 4-4v-.42L15.58 20H16zm-4-4v.42L16.42 12H16a4 4 0 0 0-4 4zM26.71 5.29a1 1 0 0 0-1.42 0l-20 20a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l20-20a1 1 0 0 0 0-1.42z'
);
$svg-legacy-favourite: 'M8 13.6L2.54 8.14a3.2 3.2 0 0 1 4.52-4.53l.94.94.94-.94a3.2 3.2 0 0 1 4.53 4.53z';
$svg-minus: 'M19 11H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2z';
$svg-outgoing-link: (
	'M13.4,8.4c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4v5.1c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.2 c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0H1.9c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4V3.9c0-0.2,0.1-0.3,0.2-0.4 c0.1-0.1,0.3-0.2,0.4-0.2h6.3c0.2,0,0.3-0.1,0.4-0.2C8.7,3,8.8,2.8,8.8,2.6c0-0.2-0.1-0.3-0.2-0.4C8.5,2.1,8.3,2,8.2,2H1.9 c-0.5,0-1,0.2-1.3,0.5C0.2,2.9,0,3.4,0,3.9v10.3c0,0.5,0.2,1,0.5,1.3C0.9,15.8,1.4,16,1.9,16h10.3c0.5,0,1-0.2,1.3-0.5 s0.5-0.8,0.5-1.3V9c0-0.2-0.1-0.3-0.2-0.4C13.7,8.4,13.5,8.4,13.4,8.4z',
	'M15.3,0H10C9.8,0,9.7,0.1,9.5,0.2C9.4,0.3,9.3,0.5,9.3,0.7S9.4,1,9.5,1.1C9.7,1.3,9.8,1.3,10,1.3h3.7L7.2,7.9 C7.1,7.9,7.1,8,7,8.1C7,8.2,7,8.2,7,8.3S7,8.5,7,8.6c0,0.1,0.1,0.2,0.1,0.2C7.3,8.9,7.3,8.9,7.4,9C7.5,9,7.6,9,7.7,9 C7.8,9,7.8,9,7.9,9c0.1,0,0.2-0.1,0.2-0.1l6.5-6.5V6c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2s0.3-0.1,0.5-0.2 C15.9,6.3,16,6.2,16,6V0.7c0-0.2-0.1-0.3-0.2-0.5C15.7,0.1,15.5,0,15.3,0L15.3,0z'
);
$svg-onecard: 'M1.1 5C.5 5 0 5.5 0 6.2v20.4c0 .7.5 1.2 1.1 1.2h18.3L25.1 5h-24zm29.8 0h-4.6l-5.7 22.9h10.3c.6 0 1.1-.5 1.1-1.2V6.2c0-.7-.5-1.2-1.1-1.2zM3.4 8.6h10.3v1.2H3.4V8.6zm9.2 2.5H3.4v1.2h9.1v-1.2z';
$svg-phone: 'M19.69 15.78a1.52 1.52 0 0 0-2.15 0L16.3 17a1 1 0 0 1-1.25.15 27.35 27.35 0 0 1-4.68-3.53 27.34 27.34 0 0 1-3.53-4.68A1 1 0 0 1 7 7.69l1.22-1.23a1.52 1.52 0 0 0 0-2.15L5.36 1.44a1.52 1.52 0 0 0-2.15 0L1.49 3.16a5.06 5.06 0 0 0-.6 6.45 62.68 62.68 0 0 0 5.9 7.59 62.67 62.67 0 0 0 7.59 5.9 5.06 5.06 0 0 0 6.45-.59l1.72-1.72a1.52 1.52 0 0 0 0-2.15zm2.16 4.31l-1.72 1.72a4.06 4.06 0 0 1-5.18.48A62.1 62.1 0 0 1 7.5 16.5 62.13 62.13 0 0 1 1.71 9a4.06 4.06 0 0 1 .48-5.18l1.72-1.67a.52.52 0 0 1 .74 0L7.52 5a.52.52 0 0 1 0 .74L6.28 7A2 2 0 0 0 6 9.46a28.25 28.25 0 0 0 3.68 4.87A28.27 28.27 0 0 0 14.53 18a2 2 0 0 0 2.47-.28l1.23-1.23a.52.52 0 0 1 .74 0l2.87 2.87a.52.52 0 0 1 .01.72zm-3-9.26a4 4 0 1 0-5.66-5.66.5.5 0 0 1-.71-.71 5 5 0 1 1 7.07 7.07.5.5 0 0 1-.71-.71zM10.34 3a.5.5 0 0 1 0-.71 8 8 0 0 1 11.32 11.37.5.5 0 0 1-.71-.71A7 7 0 0 0 11 3a.5.5 0 0 1-.66 0z';
$svg-plus: 'M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z';
$svg-plus-ui: 'M21 12.5a.5.5 0 0 1-.5.5H13v7.5a.5.5 0 0 1-1 0V13H4.5a.5.5 0 0 1 0-1H12V4.5a.5.5 0 0 1 1 0V12h7.5a.5.5 0 0 1 .5.5z';
$svg-screen-desktop: (
	'M22.6.5H1.4C.7.5 0 1.2 0 1.9v16.4c0 .8.7 1.4 1.4 1.4h8l-.6 2.9H7.2c-.3 0-.5.2-.5.5s.2.5.5.5h9.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-1.6l-.6-2.9h8c.8 0 1.4-.7 1.4-1.4V1.9c.1-.7-.6-1.4-1.3-1.4zm-8.4 22.1H9.8l.6-2.9h3.3l.5 2.9zm8.8-4.4c0 .3-.2.5-.5.5H1.4c-.3 0-.5-.2-.5-.5v-2.4H23v2.4zm0-3.3H1v-13c0-.3.2-.5.5-.5h21.1c.3 0 .5.2.5.5v13z'
);
$svg-screen-mobile: (
	'M17.2 0H6.7c-.8 0-1.5.7-1.5 1.5v21.1c0 .8.7 1.4 1.4 1.4h10.5c.8 0 1.4-.7 1.4-1.4V1.5C18.6.7 18 0 17.2 0zm.5 22.5c0 .3-.2.5-.5.5H6.7c-.3 0-.5-.2-.5-.5v-3.4h11.5v3.4zm0-4.3H6.2V1.5c0-.3.2-.5.5-.5h10.5c.3 0 .5.2.5.5v16.7z',
	'M11.9 22.1c.5 0 1-.4 1-1 0-.5-.4-1-1-1s-1 .4-1 1c.1.5.5 1 1 1zM13.4 2.9h-2.9c-.3 0-.5.2-.5.5s.2.5.5.5h2.9c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z'
);
$svg-screen-tablet: (
	'M22.4 2.8h-21C.7 2.8 0 3.4 0 4.2v16.2c0 .8.7 1.4 1.4 1.4h21c.8 0 1.4-.7 1.4-1.4V4.2c.1-.8-.6-1.4-1.4-1.4zm-21 18.1c-.3 0-.5-.2-.5-.5V4.2c0-.3.2-.5.5-.5h3.3v17.2H1.4zm21.5-.5c0 .3-.2.5-.5.5H5.7V3.7h16.7c.3 0 .5.2.5.5v16.2z',
	'M2.9 13.3c.5 0 1-.4 1-1 0-.5-.4-1-1-1s-1 .4-1 1c0 .5.4 1 1 1zM20.6 10.4c-.3 0-.5.2-.5.5v2.9c0 .3.2.5.5.5s.5-.2.5-.5v-2.9c0-.2-.2-.5-.5-.5z'
);
$svg-shopping-bag: 'M19.12 7.91a2 2 0 0 0-2-1.91H16a4 4 0 0 0-8 0H6.92a2 2 0 0 0-2 1.91l-.78 11.92a2 2 0 0 0 2 2.17h11.69a2 2 0 0 0 2-2.17zM12 4a2 2 0 0 1 2 2h-4a2 2 0 0 1 2-2zM6.88 8H8v2a1 1 0 0 0 2 0V8h4v2a1 1 0 0 0 2 0V8h1.08l.5 8H6.38zm-.71 12l.12-2h11.5l.08 2z';
$svg-tick-sign: 'M5.76338629 16.3540578c-.35674405-.4216066-.98772193-.4741881-1.40932853-.1174441-.42160661.3567441-.47418809.9877219-.11744405 1.4093285l6.76923079 8c.3994498.4720771 1.1273228.4720771 1.5267726 0L27.7633863 7.64594224c.356744-.4216066.3041625-1.05258448-.1174441-1.40932853-.4216066-.35674404-1.0525844-.30416256-1.4093285.11744405L11.7692308 23.451874l-6.00584451-7.0978162z';
$svg-truck: 'M31.7 13.7l-3-4c-.3-.5-.9-.7-1.5-.7h-5.4V7c0-1.1-.9-1.9-1.9-1.9h-18C.9 5.1 0 6 0 7v15.4c0 1.1.9 1.9 1.9 1.9h1.9c0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8h7.7c0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8h2.6c1.4 0 2.6-1.1 2.6-2.6v-7.3c.1-.2 0-.4-.2-.7zm-24 13.2c-1.4 0-2.6-1.1-2.6-2.6 0-1.4 1.1-2.6 2.6-2.6s2.6 1.1 2.6 2.6c-.1 1.4-1.2 2.6-2.6 2.6zm3.6-3.9h8.2c.2-.6.6-1.1 1-1.6V7c0-.4-.3-.6-.6-.6h-18c-.3 0-.6.3-.6.6v15.4c0 .4.3.6.6.6h2.2c.5-1.5 2-2.6 3.6-2.6 1.6.1 3 1.1 3.6 2.6zM23 26.9c-1.4 0-2.6-1.1-2.6-2.6 0-1.4 1.1-2.6 2.6-2.6 1.4 0 2.6 1.1 2.6 2.6 0 1.4-1.1 2.6-2.6 2.6zm6.4-3.9c.7 0 1.3-.6 1.3-1.3v-5.1h-9v4.1c1-.4 2-.3 2.9.1s1.6 1.2 2 2.2h2.8zm-7.6-7.6h9v-.9l-3-4c-.1-.2-.3-.3-.5-.3h-5.4v5.2z';
$svg-user-information: 'M20.29 3.69a1 1 0 0 0 1.42 0 1 1 0 0 0 .21-.32A1 1 0 0 0 22 3a1 1 0 0 0-.29-.7 1 1 0 0 0-1.42 0A1 1 0 0 0 20 3a.88.88 0 0 0 .08.39 1 1 0 0 0 .21.3zm-5 9.05a6.5 6.5 0 1 0-7.66 0A10.21 10.21 0 0 0 1 22a1 1 0 0 0 1 1 1 1 0 0 0 1-1c0-4.41 4.09-8 8.5-8s8.5 3.61 8.5 8a1 1 0 0 0 2 0 10.25 10.25 0 0 0-6.67-9.26zM11.5 12A4.5 4.5 0 1 1 16 7.5a4.51 4.51 0 0 1-4.5 4.5zM21 5a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1z';
$svg-view: 'M16 8C9.37 8 4 15.06 4 16c0 .94 5.37 8 12 8s12-7 12-8-5.37-8-12-8zm0 14c-4.61 0-8.64-4.35-9.78-6 1.14-1.65 5.17-6 9.78-6 4.61 0 8.7 4.38 9.8 6-1.1 1.62-5.11 6-9.8 6zm0-10a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z';
$svg-vintage-clock: 'M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16A16 16 0 0 0 16 0zm0 30.72C7.87 30.72 1.28 24.13 1.28 16S7.87 1.28 16 1.28 30.72 7.87 30.72 16c-.01 8.126-6.594 14.71-14.72 14.72zM15.36 3.2v1.28a.64.64 0 1 0 1.28 0V3.2a.64.64 0 1 0-1.28 0zm1.28 24.32v1.28a.64.64 0 1 1-1.28 0v-1.28a.64.64 0 1 1 1.28 0zM4.48 16.64a.64.64 0 1 0 0-1.28H3.2a.64.64 0 1 0 0 1.28h1.28zM29.44 16a.64.64 0 0 1-.64.64h-1.28a.64.64 0 1 1 0-1.28h1.28a.64.64 0 0 1 .64.64zM8.301 8.3a.64.64 0 0 0 .006-.898l-.905-.905a.64.64 0 0 0-.905.905l.905.905a.64.64 0 0 0 .9-.006zm17.203 16.298a.64.64 0 1 1-.906.905l-.905-.905a.64.64 0 1 1 .905-.905l.905.905zm-17.01-.453a.64.64 0 0 0-1.092-.452l-.905.905a.64.64 0 0 0 .905.905l.905-.905a.64.64 0 0 0 .188-.453zm17.01-17.648a.64.64 0 0 1 0 .904l-.906.905a.64.64 0 0 1-.905-.905l.905-.904a.64.64 0 0 1 .905 0zM22.4 16.64a.64.64 0 1 0 0-1.28h-5.76V9.6a.64.64 0 1 0-1.28 0V16c0 .354.287.64.64.64h6.4z';
$svg-shopper-notes: 'M12,1C5.37,1,0,5.48,0,11a9.25,9.25,0,0,0,3.6,7.14l-.45,4.27c0,.37.11.58.36.58a.71.71,0,0,0,.39-.14l3.9-2.48A14,14,0,0,0,12,21c6.63,0,12-4.48,12-10S18.63,1,12,1Zm0,18a12,12,0,0,1-3.61-.54,2,2,0,0,0-.59-.09,2.08,2.08,0,0,0-1.08.31l-1.25.8.12-1.13a2,2,0,0,0-.72-1.76A7.22,7.22,0,0,1,2,11C2,6.59,6.49,3,12,3s10,3.59,10,8S17.51,19,12,19Z';
$svg-shopper-notes-filled: 'M12,1C5.37,1,0,5.48,0,11a9.25,9.25,0,0,0,3.6,7.14l-.45,4.27c0,.54.28.74.75.44l3.9-2.48A14,14,0,0,0,12,21c6.63,0,12-4.48,12-10S18.63,1,12,1Z';
$svg-round-checked-filled: 'M12 2a10 10 0 0110 10c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm4.615 6.013a.5.5 0 00-.479.144L9.83 14.788l-1.99-1.65a.5.5 0 10-.67.742l2.353 1.978a.5.5 0 00.7-.028l6.641-6.987a.5.5 0 00-.25-.83z';
$svg-circle-filled: 'M12 2a10 10 0 0110 10c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2z';
$svg-circle-outline: 'M12 2A10 10 0 1 0 12 22A10 10 0 1 0 12 2Z M12 3A9 9 0 1 1 12 21A9 9 0 1 1 12 3Z';
$svg-express-delivery-truck: 'M9,21 L9,20.999 L9.05078819,20.9997261 C9.68203242,20.3812939 10.5464882,20 11.5,20 C12.4535118,20 13.3179676,20.3812939 13.9492118,20.9997261 L22,21 L22,9 L6,9 C6,7.9456382 6.81587779,7.08183488 7.85073766,7.00548574 L8,7 L22,7 C23.0543618,7 23.9181651,7.81587779 23.9945143,8.85073766 L24,9 L24,10 L25.3702999,10 C26.5505842,10 27.6144999,10.6910887 28.0993961,11.7541063 L28.1747715,11.934665 L29.9348239,16.5679653 C29.9635511,16.6435891 29.9829425,16.7222777 29.9926782,16.802288 L30,16.9230769 L30,20 C30,21.3288119 29.136064,22.4558407 27.9392447,22.850034 C27.9792337,23.0598033 28,23.2774765 28,23.5 C28,25.4329966 26.4329966,27 24.5,27 C22.5670034,27 21,25.4329966 21,23.5 C21,23.3302566 21.0120835,23.1633354 21.0354373,23.0000497 L14.9645627,23.0000497 C14.9879165,23.1633354 15,23.3302566 15,23.5 C15,25.4329966 13.4329966,27 11.5,27 C9.56700338,27 8,25.4329966 8,23.5 C8,23.3302566 8.01208353,23.1633354 8.03543731,23.0000497 L8,23 C6.9456382,23 6.08183488,22.1841222 6.00548574,21.1492623 L6,21 L9,21 Z M24.5,22 C23.6715729,22 23,22.6715729 23,23.5 C23,24.3284271 23.6715729,25 24.5,25 C25.3284271,25 26,24.3284271 26,23.5 C26,22.6715729 25.3284271,22 24.5,22 Z M11.5,22 C10.6715729,22 10,22.6715729 10,23.5 C10,24.3284271 10.6715729,25 11.5,25 C12.3284271,25 13,24.3284271 13,23.5 C13,22.6715729 12.3284271,22 11.5,22 Z M28,18 L24,18 L24.0000497,20.0354373 C24.1633354,20.0120835 24.3302566,20 24.5,20 C25.4535118,20 26.3179676,20.3812939 26.9492118,20.9997261 L27,21 C27.5128358,21 27.9355072,20.6139598 27.9932723,20.1166211 L28,20 L28,18 Z M25.3702999,12 L24,12 L24,16 L27.58,16 L26.3051237,12.6448883 C26.1723984,12.2954916 25.8577524,12.0526494 25.4934075,12.007575 L25.3702999,12 Z';
$svg-express-shopping-bags: 'M15,4 C16.5976809,4 17.9036609,5.24891996 17.9949073,6.82372721 L18,7 L18,10 L19,10 C19.0568199,10 19.1132708,10.0015796 19.1693113,10.0046974 C19.5588383,8.89558955 20.5823992,8.08454721 21.8053148,8.00621575 L22,8 L23,8 C24.5976809,8 25.9036609,9.24891996 25.9949073,10.8237272 L26,11 L26,13 L27,13 C28.5976809,13 29.9036609,14.24892 29.9949073,15.8237272 L30,16 L30,25 C30,26.5976809 28.75108,27.9036609 27.1762728,27.9949073 L27,28 L7,28 C5.69411778,28 4.58311485,27.1656226 4.17102423,26.0009007 L19,26 C19.5128358,26 19.9355072,25.6139598 19.9932723,25.1166211 L20,25 L20,13 C20,12.4477153 19.5522847,12 19,12 L4.17067428,12.0000889 C4.56383048,10.8876356 5.59450267,10.0763156 6.82372721,10.0050927 L7,10 L8,10 L8,7 C8,5.40231912 9.24891996,4.09633912 10.8237272,4.00509269 L11,4 L15,4 Z M27,15 L22,15 L22,26 L27,26 C27.5128358,26 27.9355072,25.6139598 27.9932723,25.1166211 L28,25 L28,16 C28,15.4477153 27.5522847,15 27,15 Z M9,22 C9.55228475,22 10,22.4477153 10,23 C10,23.5522847 9.55228475,24 9,24 L2,24 C1.44771525,24 1,23.5522847 1,23 C1,22.4477153 1.44771525,22 2,22 L9,22 Z M10,18 C10.5522847,18 11,18.4477153 11,19 C11,19.5522847 10.5522847,20 10,20 L3,20 C2.44771525,20 2,19.5522847 2,19 C2,18.4477153 2.44771525,18 3,18 L10,18 Z M11,14 C11.5522847,14 12,14.4477153 12,15 C12,15.5522847 11.5522847,16 11,16 L4,16 C3.44771525,16 3,15.5522847 3,15 C3,14.4477153 3.44771525,14 4,14 L11,14 Z M23,10 L22,10 C21.5210274,10 21.1207051,10.3367419 21.0228647,10.786394 C21.5776627,11.2921901 21.9408069,12.0033033 21.9933924,12.7993017 L22,13 L24,13 L24,11 C24,10.4477153 23.5522847,10 23,10 Z M15,6 L11,6 C10.4871642,6 10.0644928,6.38604019 10.0067277,6.88337887 L10,7 L10,10 L16,10 L16,7 C16,6.44771525 15.5522847,6 15,6 Z';

// @end-sass-export-section
$svg-definitions: (
	alarm: $svg-alarm,
	alert: $svg-alert,
	chevron-down: $svg-chevron-down,
	chevron-left: $svg-chevron-left,
	chevron-right: $svg-chevron-right,
	chevron-up: $svg-chevron-up,
	cross-ui: $svg-cross-ui,
	heart: $svg-heart,
	heart-s: $svg-heart-s,
	heart-lg: $svg-heart-lg,
	hide-password: $svg-hide-password,
	legacy-favourite: $svg-legacy-favourite,
	minus: $svg-minus,
	onecard: $svg-onecard,
	outgoing-link: $svg-outgoing-link,
	phone: $svg-phone,
	plus: $svg-plus,
	plus-ui: $svg-plus-ui,
	screen-desktop: $svg-screen-desktop,
	screen-mobile: $svg-screen-mobile,
	screen-tablet: $svg-screen-tablet,
	shopping-bag: $svg-shopping-bag,
	tick-sign: $svg-tick-sign,
	truck: $svg-truck,
	user-information: $svg-user-information,
	view: $svg-view,
	vintage-clock: $svg-vintage-clock,
	shopper-notes: $svg-shopper-notes,
	round-checked-filled: $svg-round-checked-filled,
	circle-filled: $svg-circle-filled,
	circle-outline: $svg-circle-outline,
	express-delivery-truck: $svg-express-delivery-truck,
	express-shopping-bags: $svg-express-shopping-bags,
);

// If you get an SVG that has a fill style and clip path of even-odd, add it here so it can be referenced
$svg-even-odd: (alarm, onecard, truck, express-delivery-truck, express-shopping-bags);
