@import '../4_atoms/grid';

[class*='u-grid-'] {
	@include grid-column($span: 12);
}

.u-grid {
	@include not-ie() {
		@include grid-init();

		&-2-col {
			@include media-query($size: tablet-portrait) {
				@include grid-init($columns: 2);
			}
		}
	}

	@include target-ie() {
		@include display-flex();
	}

	@for $i from 1 to 13 {
		&-mspan#{$i} {
			@include grid-column($span: $i);
		}
	}

	@for $i from 1 to 13 {
		&-tspan#{$i} {
			@include media-query($size: mobile) {
				@include not-ie() {
					@include grid-column($span: $i);
				}

				@include target-ie() {
					-ms-flex: 1 1 #{($i / 12) * 100%};
					flex: 1 1 #{($i / 12) * 100%};
				}
			}
		}
	}

	@for $i from 1 to 13 {
		&-span#{$i} {
			@include media-query($size: tablet) {
				@include not-ie() {
					@include grid-column($span: $i);
				}

				@include target-ie() {
					-ms-flex: 1 1 #{($i / 12) * 100%};
					flex: 1 1 #{($i / 12) * 100%};
				}
			}
		}
	}
}
