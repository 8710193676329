/// TODO
/// @name absolute-center
/// @group mixins
@mixin absolute-center() {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/// TODO
/// @name vertical-center
/// @group mixins
@mixin vertical-center() {
	top: 50%;
	transform: translateY(-50%);
}

/// TODO
/// @name horizontal-center
/// @group mixins
@mixin horizontal-center() {
	left: 50%;
	transform: translateX(-50%);
}
