/**
 * @sass-export-section="animations"
 */

// Legacy
@keyframes bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}

@keyframes clipleft {
	from {
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	}

	to {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}

@keyframes expand {
	from {
		max-height: 0;
	}

	to {
		max-height: 100px;
	}
}

@keyframes contract {
	from {
		max-height: 100px;
	}

	to {
		max-height: 0;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInUp--centred {
	from {
		opacity: 0;
		transform: translateY(20px);
	}

	to {
		opacity: 1;
		transform: translateY(-50%);
	}
}

@keyframes height {
	from {
		max-height: 0;
	}

	to {
		max-height: 40rem;
	}
}

@keyframes opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes growpacity {
	from {
		opacity: 0;
		transform: scale(0);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes slideDownAndShrink {
	0% {
		max-width: 150px;
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		max-width: 0;
		opacity: 0;
		transform: translateY(100px);
	}
}

@keyframes scrollerHorizontal2Items {
	0% {
		left: -100%;
	}

	1% {
		left: -50%;
	}

	49% {
		left: -50%;
	}

	51% {
		left: 0;
	}

	98% {
		left: 0;
	}

	100% {
		left: 50%;
	}
}

@keyframes scrollerHorizontal3Items {
	0% {
		left: -100%;
	}

	1% {
		left: -66.66%;
	}

	32% {
		left: -66.66%;
	}

	34% {
		left: -33.33%;
	}

	65% {
		left: -33.33%;
	}

	67% {
		left: 0%;
	}

	98% {
		left: 0%;
	}

	100% {
		left: 33.33%;
	}
}

@keyframes beatingHeart {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(0.8);
	}

	40% {
		transform: scale(1);
	}

	60% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes shakeAndShow {
	0% {
		opacity: 0;
	}

	80% {
		opacity: 1;
	}

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
// @end-sass-export-section
