$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.25rem;
$spacing-6: 1.5rem;
$spacing-8: 2rem;
$spacing-10: 2.5rem;
$spacing-12: 3rem;
$spacing-14: 3.5rem;
$spacing-16: 4rem;
$spacing-18: 4.5rem;
$spacing-20: 5rem;
$spacing-24: 6rem;
$spacing-32: 8rem;
$spacing-40: 10rem;

/**
 * @sass-export-section="spacing"
 */
$spacing: (
	s1: 0.25rem,
	s2: 0.5rem,
	s3: 0.75rem,
	s4: 1rem,
	s5: 1.25rem,
	s6: 1.5rem,
	s8: 2rem,
	s10: 2.5rem,
	s12: 3rem,
	s14: 3.5rem,
	s16: 4rem,
	s18: 4.5rem,
	s20: 5rem,
	s24: 6rem,
	s32: 8rem,
	s40: 10rem,
);
// @end-sass-export-section
