/// Applies flex box
/// @name display-flex
/// @group mixins
/// @param $direction Flex direction. Defaults to row
/// @param $align Sets align-tems. Defaults to initial
/// @param $justify Sets justify-content. Defaults to initial
/// @param $flex Applies flex value to children. Defaults to false
/// @param $flex-wrap Defaults to nowrap
/// @param $inline Applies inline-flex when set to true. Defaults to false
/// @param $flex-children Applies $flex value to children when set to true. Defaults to true
/// @param $responsive Applies column direction to mobile. Defaults to false
/// @param $responsive-justify Overrides a justify value to the mobile view. Defaults to value given in $justify
/// @param $responsive-align Overrides an align value to the mobile view. Defaults to value given in $align
/// @param $responsive-size At which breakpoint should flip to column. Defaults to mobile
@mixin display-flex(
	$direction: row,
	$align: initial,
	$justify: initial,
	$flex: false,
	$flex-wrap: nowrap,
	$inline: false,
	$flex-children: true,
	$responsive: false,
	$responsive-justify: false,
	$responsive-align: false,
	$responsive-size: mobile,
) {
	align-items: $align;
	-ms-display: flex;
	display: if($inline, inline-flex, flex);
	flex-direction: $direction;
	flex-wrap: $flex-wrap;
	justify-content: $justify;

	@if ($flex-children) {
		& > * {
			@if $flex {
				flex: $flex;
				-ms-flex: $flex;
			}

  @else {
				flex: 0 1 auto;
				-ms-flex: 0 0 auto;
			}
		}
	}

	@if ($responsive) {
		@include media-query($size: $responsive-size, $max: true) {
			flex-direction: column;

			@if ($responsive-align) {
				align-items: $responsive-align;
			}

			@if ($responsive-justify) {
				justify-content: $responsive-justify;
			}
		}
	}
}
