@import './colors';

$focus-shadow-color: map-get($colors, selago);
$default-shadow-color-extra-faded: rgba(black, 0.15);
$default-shadow-color-faded: rgba(black, 0.35);
$default-shadow-color: black;
$shadow-focus: 0 0 0 4px $focus-shadow-color;
$shadow-focus-inset: inset 0px 0px 2px 0px map-get($colors, fun-green);

/**
 * @sass-export-section="shadows"
 */
$shadows: (
	xtra-light: 0 3px 3px 0 $default-shadow-color-extra-faded,
	nav: 0 4px 10px 0 $default-shadow-color-faded,
	sub-nav: 0 6px 6px 0 $default-shadow-color-faded,
	small: 0px 2px 5px 0px $default-shadow-color-faded,
	medium: 0px 6px 20px 0px $default-shadow-color-faded,
	large: 0px 9px 30px 0px $default-shadow-color-faded,
	focus: 0 0 0 4px $focus-shadow-color,
	focus-inset: $shadow-focus-inset,
	underlay: linear-gradient(to top, $default-shadow-color-faded 40%, rgba($default-shadow-color, 0) 100%),
);
// @end-sass-export-section
