@import 'spacing';

$radius-default: $spacing-1;
$radius-md: $spacing-2;
$radius-lg: $spacing-4;
$radius-xl: $spacing-6;
$radius-xxl: $spacing-8;
$radius-half: 50%;

/**
 * @sass-export-section="radii"
 */
$radii: (
	default: $radius-default,
	md: $radius-md,
	lg: $radius-lg,
	xl: $radius-xl,
	xxl: $radius-xxl,
	circle: $radius-half,
);
// @end-sass-export-section