/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");
:focus {
  outline: 1px auto #007837;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

body.blur {
  filter: blur(4px) !important;
}

/**
 * @sass-export-section="animations"
 */
@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes clipleft {
  from {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
@keyframes expand {
  from {
    max-height: 0;
  }
  to {
    max-height: 100px;
  }
}
@keyframes contract {
  from {
    max-height: 100px;
  }
  to {
    max-height: 0;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInUp--centred {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(-50%);
  }
}
@keyframes height {
  from {
    max-height: 0;
  }
  to {
    max-height: 40rem;
  }
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes growpacity {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slideDownAndShrink {
  0% {
    max-width: 150px;
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    max-width: 0;
    opacity: 0;
    transform: translateY(100px);
  }
}
@keyframes scrollerHorizontal2Items {
  0% {
    left: -100%;
  }
  1% {
    left: -50%;
  }
  49% {
    left: -50%;
  }
  51% {
    left: 0;
  }
  98% {
    left: 0;
  }
  100% {
    left: 50%;
  }
}
@keyframes scrollerHorizontal3Items {
  0% {
    left: -100%;
  }
  1% {
    left: -66.66%;
  }
  32% {
    left: -66.66%;
  }
  34% {
    left: -33.33%;
  }
  65% {
    left: -33.33%;
  }
  67% {
    left: 0%;
  }
  98% {
    left: 0%;
  }
  100% {
    left: 33.33%;
  }
}
@keyframes beatingHeart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shakeAndShow {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@font-face {
  font-display: swap;
  font-family: "Fresh";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/fresh-sans-medium-1.350.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Fresh";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/FreshSans-Bold_web.woff2") format("woff2");
}
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="icons"
 */
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: inherit !important;
  }
  .hidden-print {
    display: none !important;
  }
}
@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {}
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="icons"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="icons"
 */
/**
 * @sass-export-section="spacing"
 */
[class*=u-grid-] {
  grid-column: span 12;
  -ms-grid-column-span: 12;
}

@supports not (-ms-high-contrast: none) {
  .u-grid {
    display: grid;
    justify-content: center;
    -ms-grid-columns: (8.3333333333%) [12];
    grid-template-columns: repeat(12, minmax(0, 8.3333333333%));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .u-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media screen and (min-width: 768px) {
    .u-grid-2-col {
      display: grid;
      justify-content: center;
      -ms-grid-columns: (50%) [2];
      grid-template-columns: repeat(2, minmax(0, 50%));
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }
  }
  @media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .u-grid-2-col {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .u-grid {
    align-items: initial;
    -ms-display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: initial;
  }
  .u-grid > * {
    flex: 0 1 auto;
    -ms-flex: 0 0 auto;
  }
}
.u-grid-mspan1 {
  grid-column: span 1;
  -ms-grid-column-span: 1;
}
.u-grid-mspan2 {
  grid-column: span 2;
  -ms-grid-column-span: 2;
}
.u-grid-mspan3 {
  grid-column: span 3;
  -ms-grid-column-span: 3;
}
.u-grid-mspan4 {
  grid-column: span 4;
  -ms-grid-column-span: 4;
}
.u-grid-mspan5 {
  grid-column: span 5;
  -ms-grid-column-span: 5;
}
.u-grid-mspan6 {
  grid-column: span 6;
  -ms-grid-column-span: 6;
}
.u-grid-mspan7 {
  grid-column: span 7;
  -ms-grid-column-span: 7;
}
.u-grid-mspan8 {
  grid-column: span 8;
  -ms-grid-column-span: 8;
}
.u-grid-mspan9 {
  grid-column: span 9;
  -ms-grid-column-span: 9;
}
.u-grid-mspan10 {
  grid-column: span 10;
  -ms-grid-column-span: 10;
}
.u-grid-mspan11 {
  grid-column: span 11;
  -ms-grid-column-span: 11;
}
.u-grid-mspan12 {
  grid-column: span 12;
  -ms-grid-column-span: 12;
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan1 {
      grid-column: span 1;
      -ms-grid-column-span: 1;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan1 {
    -ms-flex: 1 1 8.3333333333%;
    flex: 1 1 8.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan2 {
      grid-column: span 2;
      -ms-grid-column-span: 2;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan2 {
    -ms-flex: 1 1 16.6666666667%;
    flex: 1 1 16.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan3 {
      grid-column: span 3;
      -ms-grid-column-span: 3;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan3 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan4 {
      grid-column: span 4;
      -ms-grid-column-span: 4;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan4 {
    -ms-flex: 1 1 33.3333333333%;
    flex: 1 1 33.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan5 {
      grid-column: span 5;
      -ms-grid-column-span: 5;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan5 {
    -ms-flex: 1 1 41.6666666667%;
    flex: 1 1 41.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan6 {
      grid-column: span 6;
      -ms-grid-column-span: 6;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan6 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan7 {
      grid-column: span 7;
      -ms-grid-column-span: 7;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan7 {
    -ms-flex: 1 1 58.3333333333%;
    flex: 1 1 58.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan8 {
      grid-column: span 8;
      -ms-grid-column-span: 8;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan8 {
    -ms-flex: 1 1 66.6666666667%;
    flex: 1 1 66.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan9 {
      grid-column: span 9;
      -ms-grid-column-span: 9;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan9 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan10 {
      grid-column: span 10;
      -ms-grid-column-span: 10;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan10 {
    -ms-flex: 1 1 83.3333333333%;
    flex: 1 1 83.3333333333%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan11 {
      grid-column: span 11;
      -ms-grid-column-span: 11;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan11 {
    -ms-flex: 1 1 91.6666666667%;
    flex: 1 1 91.6666666667%;
  }
}
@media screen and (min-width: 640px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-tspan12 {
      grid-column: span 12;
      -ms-grid-column-span: 12;
    }
  }
}
@media screen and (min-width: 640px) and (-ms-high-contrast: active), screen and (min-width: 640px) and (-ms-high-contrast: none) {
  .u-grid-tspan12 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span1 {
      grid-column: span 1;
      -ms-grid-column-span: 1;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span1 {
    -ms-flex: 1 1 8.3333333333%;
    flex: 1 1 8.3333333333%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span2 {
      grid-column: span 2;
      -ms-grid-column-span: 2;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span2 {
    -ms-flex: 1 1 16.6666666667%;
    flex: 1 1 16.6666666667%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span3 {
      grid-column: span 3;
      -ms-grid-column-span: 3;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span3 {
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span4 {
      grid-column: span 4;
      -ms-grid-column-span: 4;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span4 {
    -ms-flex: 1 1 33.3333333333%;
    flex: 1 1 33.3333333333%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span5 {
      grid-column: span 5;
      -ms-grid-column-span: 5;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span5 {
    -ms-flex: 1 1 41.6666666667%;
    flex: 1 1 41.6666666667%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span6 {
      grid-column: span 6;
      -ms-grid-column-span: 6;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span6 {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span7 {
      grid-column: span 7;
      -ms-grid-column-span: 7;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span7 {
    -ms-flex: 1 1 58.3333333333%;
    flex: 1 1 58.3333333333%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span8 {
      grid-column: span 8;
      -ms-grid-column-span: 8;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span8 {
    -ms-flex: 1 1 66.6666666667%;
    flex: 1 1 66.6666666667%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span9 {
      grid-column: span 9;
      -ms-grid-column-span: 9;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span9 {
    -ms-flex: 1 1 75%;
    flex: 1 1 75%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span10 {
      grid-column: span 10;
      -ms-grid-column-span: 10;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span10 {
    -ms-flex: 1 1 83.3333333333%;
    flex: 1 1 83.3333333333%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span11 {
      grid-column: span 11;
      -ms-grid-column-span: 11;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span11 {
    -ms-flex: 1 1 91.6666666667%;
    flex: 1 1 91.6666666667%;
  }
}
@media screen and (min-width: 970px) {
  @supports not (-ms-high-contrast: none) {
    .u-grid-span12 {
      grid-column: span 12;
      -ms-grid-column-span: 12;
    }
  }
}
@media screen and (min-width: 970px) and (-ms-high-contrast: active), screen and (min-width: 970px) and (-ms-high-contrast: none) {
  .u-grid-span12 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}

/**
 * @sass-export-section="borders"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="radii"
 */
/**
 * @sass-export-section="spacing"
 */
/**
 * @sass-export-section="colours"
 */
/**
 * @sass-export-section="shadows"
 */
/**
 * @sass-export-section="transitions"
 */
.cdk-overlay-container {
  z-index: 100000;
}

.overlay--transparent {
  background-color: transparent;
}
.overlay--opaque {
  background-color: rgba(0, 0, 0, 0.35);
}
.overlay-panel {
  max-width: calc(100% - 2 * 1rem);
}
.overlay-panel > * {
  width: 100%;
}

.cdk-overlay-pane.onboarding-modal {
  position: static;
}

cdx-alert + cdx-page-heading {
  padding-top: 0 !important;
}