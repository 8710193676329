@import '../1_settings/_config';
@import '../2_tools/functions/build-transition';
@import '../2_tools/functions/svg';
@import '../2_tools/mixins/pseudo';

$link-color: map-deep-get($config, colors, link, primary);
$link-active-color: map-get($colors, parsley);

$link-secondary-color: map-deep-get($config, colors, link, secondary);
$link-secondary-hover-color: map-deep-get($config, colors, link, secondary-hover);
$outgoing-link-margin-left: $spacing-1;

/// TODO
/// @name link
/// @group atoms
@mixin link-init($hover-underline: true, $type: primary, $hover-type: primary) {
	color: if($type == primary, $link-color, $link-secondary-color);
	text-decoration: none;
	transition: build-transition(default, default, all);
	transition-property: background-color, color;

	&:hover {
		color: if($hover-type == primary, $link-color, $link-secondary-hover-color);
		text-decoration: underline;
	}

	&:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;
		}

		&:focus {
			outline: 0;
		}
	}

	*[role='navigation'] li & {
		@include link-remove-hover-underline();
	}

	@if not $hover-underline {
		&:hover {
			text-decoration: none;
		}
	}
}

/// TODO
/// @name link-active
/// @group atoms
@mixin link-active() {
	font-weight: 700;

	&,
	&:hover {
		color: $link-active-color;
	}
}

/// TODO
/// @name link-external
/// @group atoms
@mixin link-external() {
	///@todo currently this breaks legacy
	// &[target^="_blank"],
	// &[href^="http://"],
	// &[href^="https://"] {
	//   padding: 2px 0 0; // Makes the Outline look nicer
	//   position: relative;

	//   &:after,
	//   &:after,
	//   &:after {
	//     background: svg(
	//       $fill-color: $link-color,
	//       $path: 'outgoing-link',
	//       $svg-size: $spacing-4
	//     );
	//     content: '';
	//     display: inline-block;
	//     height: $spacing-4;
	//     margin-left: $outgoing-link-margin-left;
	//     top: 0;
	//     width: $spacing-4;
	//   }
	// }
}

/// TODO
/// @name link-cover
/// @group atoms
@mixin link-cover() {
	bottom: 0;
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;

	&:hover {
		text-decoration: none;
	}
}

/// TODO
/// @name link-dark
/// @group atoms
@mixin link-dark($background-transition: false, $hover-color: false) {
	color: white;
	text-decoration: underline;

	@if not $background-transition {
		&:hover,
		&:active {
			color: white;
			opacity: 0.9;
		}
	}

 @else {
		@if not $hover-color {
			@error '[Mixin Link Dark] Must add a hover colour if setting a background transition';
		}

		&:hover,
		&:active {
			background-color: white;
			color: $hover-color;
			opacity: 0.9;
		}
	}
}

/// TODO
/// @name link-remove-hover-underline
/// @group atoms
@mixin link-remove-hover-underline() {
	&:hover {
		text-decoration: none;
	}
}
