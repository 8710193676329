/**
 * @sass-export-section="borders"
 */
// To be deprecated. Only used for documentation
$borders: (
	default: 1px,
	w4: 4px,
	w8: 8px,
);
// @end-sass-export-section
